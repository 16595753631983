<template>
  <div>
    <CRow>
      <CCol sm="12">
        <AssetConditionsTable
          :items="asset_conditions"
          hover
          striped
          border
          small
          fixed
          caption="Condiciones de los Activos"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AssetConditionsTable from '../../components/asset_conditions/AssetConditionsTable.vue'
import ws from '../../services/asset_conditions';
import store from '../../store'

export default {
  name: 'AssetConditions',
  components: { AssetConditionsTable },
  data: function () {
		return {
            asset_conditions: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get();

    if(response.type == "success"){
      this.asset_conditions = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.asset_conditions = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
